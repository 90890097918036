import React from 'react';

function Contact() {
  return (
    <section className="contact-section">
      <h2>Contact</h2>
      <p>Provide your contact details or form here</p>
    </section>
  );
}

export default Contact;
