import React from 'react';

function Blog() {
  return (
    <section className="blog-section">
      <h2>Blog</h2>
      {/* Add your blog posts here */}
    </section>
  );
}

export default Blog;
